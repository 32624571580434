// versionDetailsConfig.js
export const VERSION_INFO = Object.freeze({
    number: '1.0.25.1',
    date: '24th Dec',
    fullVersion: 'Zippie.1.0.25.1',
    formattedVersion: 'V.1.0.25.1 · 24th Feb',
    changes: [
      // v1.0.23
      // 'Added automatic activity selection for Group Observations',
      // 'Added toggle to switch between automatic activity selection and existing method of date selection',
      // 'Added image upload option to Weekly Observations',
      // 'Renamed Individual Observations in Profile Dropdown to Term Reports',
      // 'Fixed observation image preview not closing on opening a new observation'
      // v1.0.25
      'Fixed issue causing Activity Observations image upload page to not appear in some cases',
      'Fixed wrong page number of Engagement level question in Activity Observations form',
      'Form data will be saved in case Observation fails',
      'Activity Observation save failure messages will now indicate which fields were not filled',
      'Fixed issues when fetching some observation fields',
      'New: Fixed Pending Observations not being saved even if all questions are answered',
      'New: Fixed Not being able to create new Activity Observation from Pending Observation save confirmation modal'
      // { text: '', highlight: true }
    ]
  })




 

